import React, { useEffect, useRef, useState } from "react";
import { cn, f2u, DataObserveKey, VisualComponent } from "@siteimprove/fancylib";
import * as scss from "./progress-bar.scss";

export type ProgressbarProps = {
	value: number;
	total: number;
	/** If color is specified, it will take precedence over the colorRange property */
	color?: string;
	colorRange?: "cool" | "warm";
	reverseColorRange?: boolean;
	"aria-hidden"?: boolean;
} & DataObserveKey &
	VisualComponent;

export function ProgressBar(props: ProgressbarProps): JSX.Element {
	const {
		value,
		total,
		color,
		colorRange = "warm",
		reverseColorRange,
		"aria-hidden": ariaHidden,
		style,
		className,
	} = props;

	useEffect(() => {
		setProgressBarWidth(generateProgressWidth());
		calculateProgressAndSetBackgroundColor();
	}, [value, total]);

	const generateProgressWidth = () => {
		if (total === 0) {
			return 0;
		} else if (value > total) {
			return 100;
		} else {
			return (value / total) * 100;
		}
	};

	const [progressBarWidth, setProgressBarWidth] = useState(0);

	const progressContainerRef = useRef<HTMLDivElement>(null);
	const progressBarRef = useRef<HTMLSpanElement>(null);

	useEffect(() => {
		if (!progressBarRef.current) return;
		if (color) return;

		const transitionStart = () => {
			startProgressCalculation();
		};

		const transitionEnd = () => {
			stopProgressCalculation();
		};

		progressBarRef.current.addEventListener("transitionstart", transitionStart);
		progressBarRef.current.addEventListener("transitionend", transitionEnd);

		return () => {
			document.removeEventListener("transitionstart", transitionStart);
			document.removeEventListener("transitionend", transitionEnd);
		};
	}, []);

	let intervalId: NodeJS.Timeout;

	const startProgressCalculation = () => {
		intervalId = setInterval(calculateProgressAndSetBackgroundColor, 100);
	};

	const calculateProgressAndSetBackgroundColor = () => {
		const progressContainerWidth = progressContainerRef.current?.offsetWidth ?? 0;
		const progressBarWidth = progressBarRef.current?.offsetWidth ?? 0;
		const currentProgress =
			progressContainerWidth === 0 ? 0 : progressBarWidth / progressContainerWidth;

		const heatRangeWarm = (currentProgress: number) => {
			let bgColor = "";
			if (currentProgress == 1) {
				bgColor = "var(--color--heat--warm--1)";
			} else if (currentProgress >= 0.95) {
				bgColor = "var(--color--heat--warm--2)";
			} else if (currentProgress >= 0.9) {
				bgColor = "var(--color--heat--warm--3)";
			} else if (currentProgress >= 0.85) {
				bgColor = "var(--color--heat--warm--4)";
			} else if (currentProgress >= 0.8) {
				bgColor = "var(--color--heat--warm--5)";
			} else if (currentProgress >= 0.7) {
				bgColor = "var(--color--heat--warm--6)";
			} else if (currentProgress >= 0.65) {
				bgColor = "var(--color--heat--warm--7)";
			} else if (currentProgress >= 0.6) {
				bgColor = "var(--color--heat--warm--8)";
			} else if (currentProgress >= 0.5) {
				bgColor = "var(--color--heat--warm--9)";
			} else if (currentProgress >= 0.4) {
				bgColor = "var(--color--heat--warm--10)";
			} else if (currentProgress >= 0.3) {
				bgColor = "var(--color--heat--warm--11)";
			} else {
				bgColor = "var(--color--heat--warm--12)";
			}
			if (progressBarRef.current) progressBarRef.current.style.backgroundColor = bgColor;
		};

		const heatRangeWarmReverse = (currentProgress: number) => {
			let bgColor = "";
			if (currentProgress == 1) {
				bgColor = "var(--color--heat--warm--12)";
			} else if (currentProgress >= 0.95) {
				bgColor = "var(--color--heat--warm--11)";
			} else if (currentProgress >= 0.9) {
				bgColor = "var(--color--heat--warm--10)";
			} else if (currentProgress >= 0.85) {
				bgColor = "var(--color--heat--warm--9)";
			} else if (currentProgress >= 0.8) {
				bgColor = "var(--color--heat--warm--8)";
			} else if (currentProgress >= 0.7) {
				bgColor = "var(--color--heat--warm--7)";
			} else if (currentProgress >= 0.65) {
				bgColor = "var(--color--heat--warm--6)";
			} else if (currentProgress >= 0.6) {
				bgColor = "var(--color--heat--warm--5)";
			} else if (currentProgress >= 0.5) {
				bgColor = "var(--color--heat--warm--4)";
			} else if (currentProgress >= 0.4) {
				bgColor = "var(--color--heat--warm--3)";
			} else if (currentProgress >= 0.3) {
				bgColor = "var(--color--heat--warm--2)";
			} else {
				bgColor = "var(--color--heat--warm--1)";
			}
			if (progressBarRef.current) progressBarRef.current.style.backgroundColor = bgColor;
		};

		const heatRangeCool = (currentProgress: number) => {
			let bgColor = "";
			if (currentProgress == 1) {
				bgColor = "var(--color--heat--cool--10)";
			} else if (currentProgress >= 0.95) {
				bgColor = "var(--color--heat--cool--9)";
			} else if (currentProgress >= 0.9) {
				bgColor = "var(--color--heat--cool--8)";
			} else if (currentProgress >= 0.8) {
				bgColor = "var(--color--heat--cool--7)";
			} else if (currentProgress >= 0.65) {
				bgColor = "var(--color--heat--cool--6)";
			} else if (currentProgress >= 0.6) {
				bgColor = "var(--color--heat--cool--5)";
			} else if (currentProgress >= 0.5) {
				bgColor = "var(--color--heat--cool--4)";
			} else if (currentProgress >= 0.4) {
				bgColor = "var(--color--heat--cool--3)";
			} else if (currentProgress >= 0.3) {
				bgColor = "var(--color--heat--cool--2)";
			} else {
				bgColor = "var(--color--heat--cool--1)";
			}
			if (progressBarRef.current) progressBarRef.current.style.backgroundColor = bgColor;
		};

		const heatRangeCoolReverse = (currentProgress: number) => {
			let bgColor = "";
			if (currentProgress == 1) {
				bgColor = "var(--color--heat--cool--1)";
			} else if (currentProgress >= 0.95) {
				bgColor = "var(--color--heat--cool--2)";
			} else if (currentProgress >= 0.9) {
				bgColor = "var(--color--heat--cool--3)";
			} else if (currentProgress >= 0.8) {
				bgColor = "var(--color--heat--cool--4)";
			} else if (currentProgress >= 0.7) {
				bgColor = "var(--color--heat--cool--5)";
			} else if (currentProgress >= 0.6) {
				bgColor = "var(--color--heat--cool--6)";
			} else if (currentProgress >= 0.5) {
				bgColor = "var(--color--heat--cool--7)";
			} else if (currentProgress >= 0.4) {
				bgColor = "var(--color--heat--cool--8)";
			} else if (currentProgress >= 0.3) {
				bgColor = "var(--color--heat--cool--9)";
			} else {
				bgColor = "var(--color--heat--cool--10)";
			}
			if (progressBarRef.current) progressBarRef.current.style.backgroundColor = bgColor;
		};

		reverseColorRange
			? colorRange === "cool"
				? heatRangeCoolReverse(currentProgress)
				: heatRangeWarmReverse(currentProgress)
			: colorRange === "cool"
			? heatRangeCool(currentProgress)
			: heatRangeWarm(currentProgress);
	};

	const stopProgressCalculation = () => {
		clearInterval(intervalId);
	};

	return (
		<div
			data-component="progress-bar"
			data-observe-key={props["data-observe-key"]}
			ref={progressContainerRef}
			className={cn(scss.progressContainer, className)}
			style={style}
		>
			<span className={scss.progressWrapper}>
				<span
					ref={progressBarRef}
					role="progressbar"
					style={{ backgroundColor: color, width: `${progressBarWidth}%` }}
					aria-hidden={ariaHidden}
					aria-valuemin={f2u(!ariaHidden && 0)}
					aria-valuenow={f2u(!ariaHidden && value)}
					aria-valuemax={f2u(!ariaHidden && total)}
					aria-valuetext={f2u(!ariaHidden && (total == 100 ? value + "%" : value + "/" + total))}
				></span>
			</span>
		</div>
	);
}
